import { Component, ElementRef, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController, ModalOptions, Platform, ToastController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { EventKey } from 'src/app/util/event-key';
import { Events } from 'src/app/util/Events';
import { GlobalVars } from 'src/app/util/global-vars';
import { Origin } from 'src/app/util/origin';
import { Util } from 'src/app/util/util';
import { ClientService } from 'src/app/services/client.service';
import { NotifyMeService } from 'src/app/services/notify-me.service';
import OneSignal from 'onesignal-cordova-plugin';
import { OneSignalPlayerService } from 'src/app/services/one-signal-player.service';
import { CategoryService } from 'src/app/services/category.service';
import { CartService } from 'src/app/services/cart.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MethodPaymentService } from 'src/app/services/method-payment.service';
import { NotificationConfigService } from 'src/app/services/notification-config.service';
import * as numeral from 'numeral';
import { StorageService } from './services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistPage } from './pages/wishlist/wishlist.page';
import { DeliveryPaymentInfoPage } from './pages/delivery-payment-info/delivery-payment-info.page';
import { ContactUsPage } from './pages/contact-us/contact-us.page';
import { AboutAppPage } from './pages/about-app/about-app.page';
import { WalletInfoPage } from './pages/wallet-info/wallet-info.page';
import { HtmlContentPage } from './pages/html-content/html-content.page';
import { NavigationDataService } from './services/navigation-data.service';
import { CreateUserPage } from './pages/create-user/create-user.page';
import { LoginPage } from './pages/login/login.page';
import { BackgroundColorOptions, StatusBar, Style} from '@capacitor/status-bar';
import { CallBack } from './util/callback';
import { FacebookPixelService } from './services/facebook-pixel.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  rootPage: any;
  userObj: any;
  clientObj: any;
  settingsObj: any;
  showShareMenu: boolean = false;
  socialMediaDescription: string = null;
  socialMediaImage: string = null;
  socialLink: string = null;
  categoryList: Array<any>;
  categoryListProductQt: Map<number, any> = new Map<number, any>();
  timeout: any;
  totalItemCart: number;
  deferredPrompt: any;
  showAddHomeButton: boolean;
  displayedInstallButton: boolean;
  showMenuItemBonusInfo: boolean = false;
  showMenuItemWishlist: boolean = false;
  openPangetimeOutId;
  isShowClientList = false;
  rootParameter: any;
  styleImgLogoMenu: string = "ion-text-center ion-padding";

  //TODO-M: @ViewChild(Nav) nav: Nav;
  //TODO-M: @ViewChild('appTabs') appTabs: Tab;

  constructor(
    public router: Router,
    public storage: StorageService,
    public platform: Platform,
    public clientProvider: ClientService,
    public notifyMeProvider: NotifyMeService,
    public events: Events,
    public userService: UserService,
    public alertController: AlertController,
    public modalController: ModalController,
    public oneSiginalPlayerProvider: OneSignalPlayerService,
    public globalVars: GlobalVars,
    public categoryService: CategoryService,
    public cartService: CartService,
    public clientService: ClientService,
    public toastCtrl: ToastController,
    public settingsProvider: SettingsService,
    public loadingControle: LoadingController,
    public methodPaymentProvider: MethodPaymentService,
    public notificationConfigProvider: NotificationConfigService,
    public navigationDataService:NavigationDataService,
    private elementRef:ElementRef,
    public activateRoute: ActivatedRoute,
    public facebookPixelService: FacebookPixelService) {

    platform.ready().then(() => {
      this.stylizeStatusBar();
      this.loadNotificationConfig();
      this.setUpForPwaInstallButton();
      this.layoutAdjusteHeader();
      this.setUpFaceBookPixel();
    });
  }

  private setUpFaceBookPixel(){
    this.facebookPixelService.init();
  }

  private stylizeStatusBar(){
    if(!this.globalVars.isPwa()){
      let options: BackgroundColorOptions = {
        color: "#1d1c1a" //MAKEAPP13
      };

      StatusBar.setBackgroundColor(options).then(()=>{
        console.log("StatusBar.setBackgroundColor setado.");
      });

      StatusBar.setStyle({ style: Style.Light }).then(()=>{ //MAKEAPP14
        console.log("StatusBar.setStyle setado.");
      });
    }
  }
  
  //TODO: Ajuste criado para resolver bug do leyout no header.
  private layoutAdjusteHeader() {
    
    if(this.platform.is("ios") && !this.platform.is("mobileweb")){
      this.elementRef.nativeElement.style.setProperty('--ion-safe-area-top', '50px');
      this.styleImgLogoMenu = "ion-text-center img-logo-menu-adjuste";
    }
    
  }

  ngOnInit():void {

    //this.getInfoVersion();
    this.getPIdURLParamenter();

    this.storage.init((stg)=>{

      this.cartService.init();

      this.getUserInLocalStorage();
      this.setUpRootPage();
      this.setupEventChandClientApp();
      this.setupEventChandClientAppByProductPage();
      this.setupEventClickOpenPage();
      this.setupEventLogin();
      this.setupEventLogout();
      this.setupEventVisibleSplitPanel();
      this.setupEventHiddenSplitPanel();
      this.setupEventLoginVendeMais();
      this.setupEventCartUpdate();
      this.setupEventCartRemoved();
      this.setupEventUserClientStorageChange();
      this.setupEventBeforeInstallPrompt();
      this.setupEventSettingsChange();

      this.setUpNumeral();
      this.setUpIsPwa();
      this.loadUserClient();
      this.loadClient();
      this.loadListMethodPayment();
      this.setUpTotalCartInfo();
      this.setUpLayout();
    });

  }

  private getPIdURLParamenter() {

    let cid = Util.getURLParameterValue(document.URL,'cid'); //Client ID
    let bid = Util.getURLParameterValue(document.URL,'bid'); //Buyer ID
    let pid: any = Util.getURLParameterValue(document.URL, "pid");

    if (cid && cid.value) {
      this.setUpClient(cid.value);
    }
    if (pid && pid.value) {
      this.globalVars.setPIdURLParameter(pid);
    }
    if (bid && bid.value) {
      //TODO: Implementar logica do que fazer com essa informacao.
    }
  }

  private setUpClient(clientId){

    if(this.globalVars.getClientObj() && this.globalVars.getClientChildren() 
        && this.globalVars.getClientChildren().length > 1){
          
      if(this.globalVars.getClientObj().id == clientId){
        this.dispatchChangeClientEvent(this.globalVars.getClientObj());
      }else {
        for(let client of this.globalVars.getClientChildren()){
          if(clientId == client.id){
            this.dispatchChangeClientEvent(client);
            break;
          }
        }
      }
    }
  }

  private dispatchChangeClientEvent(client){
    let cnpj = Util.getCNPJCPFWithoutPoint(client.cnpj);
    let authText = cnpj+':aWd%$,Msd5tY@!';
    this.globalVars.setAuthText(authText);

    this.events.publish(EventKey.CHANGE_CLIENT_APP_BY_PRODUCT_PAGE,{authText: authText});
  }

  private setUpTotalCartInfo(){
    this.cartService.getCartJson((jsonCart) => {
      if (jsonCart) {
        let total: number = jsonCart.productlist.length;
        this.totalItemCart = (total > 0) ? total : null;
      } else {
        this.totalItemCart = null;
      }
    });

    this.setUpLayout();
  }

  /*TODO:Esse método deve ser retirado quando não houver mais a necessidade de uso do isVisibleSplitPanel
    que deve ser subistituido por outro método chamado isLargeScreen() na classe GlobalVars e deve ser usado
    em todo codido no lugar de isVisibleSplitPanel()
  */
  private setUpLayout() {
    if (this.isLarge()) {
      this.globalVars.setIsVisibleSplitPane(true);
      this.events.publish(EventKey.VISIBLE_SPLIT_PANEL);
    } else {
      this.globalVars.setIsVisibleSplitPane(false);
      this.events.publish(EventKey.HIDDEN_SPLIT_PANEL);
      this.storage.get(Util.VENDEMAIS_LOGED_KEY).then((value) => {
        if (value) {
          this.globalVars.setLoggedInVendeMais(true);
          this.setRooPage(GlobalVars.VENDEMAIS_TABS_PAGE);
        }
      });
    }
  }

  private setUpRootPage() {

    if (this.platform.width() >= 992) {
      this.setRooPage(GlobalVars.HOME_ROOT_PAGE);
    } else {
      this.setRooPage(GlobalVars.TABS_ROOT_PAGE);
    }
  }

  private setupEventChandClientApp(){
    let that = this;
    that.events.subscribe(EventKey.CHANGE_CLIENT_APP, (changeClientObj) => {
      this.changeClientApp(()=>{that.setUpRootPage();},changeClientObj);
    });
  }

  private setupEventChandClientAppByProductPage(){
    let that = this;
    that.events.subscribe(EventKey.CHANGE_CLIENT_APP_BY_PRODUCT_PAGE, (changeClientObj) => {
      this.changeClientApp(()=>{},changeClientObj);
    });
  }

  private changeClientApp(callBack,changeClientObj){
    let that = this;

    this.isShowClientList = false;

      //No evento de mudanca de app do client (Multifiliais) seta o client(Owner) do usuario logado.
      that.clientProvider.getUserClient((clientData) => {

        that.globalVars.setClientObj(clientData.owner);
        
        that.cartService.getCartJson((cartJson)=>{
          if(cartJson && cartJson.clientid != clientData.owner.id){
            that.cartService.removeCart(function(){});
          }
        });

        that.buildCategoryMenuList();
        
        if(changeClientObj.loadingObj){
          changeClientObj.loadingObj.dismiss();
        }

        if(callBack){
          callBack();
        }

      },(errorData)=>{
        console.error(errorData);
      });
  }
  
  private setupEventClickOpenPage(){
    /**
     //TODO-M:
    let that = this;
    
    that.events.subscribe(EventKey.CLICK_OPEN_PAGE, (pageName) => {

      if (that.globalVars.isPwa()) {

        if (that.openPangetimeOutId) {
          clearTimeout(that.openPangetimeOutId);
        }

        that.openPangetimeOutId = setTimeout(function () {
          let pageLoadedMap: Map<string, boolean> = that.globalVars.getPageLoadedControlMap();

          pageLoadedMap.forEach((value: boolean, key: string) => {
            if (!value) {
              that.confirmReloadPage();
              return;
            }
          });
        }, 4000);
      }

    });
    */
  }

  private setupEventLogin(){
    let that = this;

    that.events.subscribe(EventKey.LOGIN, (userObj) => {

      that.globalVars.setBuyerObj(userObj);
      this.setUserObj(userObj);
      this.events.publish(EventKey.CHANGE_GLOBAL_BUYER_OBJ);
    });
  }

  private setupEventLogout(){
    let that = this;

    this.events.subscribe(EventKey.LOGOUT, (jsonCart) => {
      that.userService.storageLocalUser(null);
      that.globalVars.setBuyerObj(null);
      that.setUserObj(null);
    });
  }

  private setupEventVisibleSplitPanel(){
    let that = this;

    that.events.subscribe(EventKey.VISIBLE_SPLIT_PANEL, (data) => {
      that.setRooPage(GlobalVars.HOME_ROOT_PAGE);
      if (!that.categoryList || that.categoryList.length == 0) {
        that.buildCategoryMenuList();
      }
    });
  }

  private setupEventHiddenSplitPanel(){
    let that = this;

    that.events.subscribe(EventKey.HIDDEN_SPLIT_PANEL, (data) => {
      this.setUpRootPage();
    } );
  }

  private setupEventLoginVendeMais(){
    let that = this;

    that.events.subscribe(EventKey.LOGIN_VENDEMAIS, (data) => {
      this.storage.set(Util.VENDEMAIS_LOGED_KEY, true).then(() => {
        this.globalVars.setLoggedInVendeMais(true);
        this.setRooPage(GlobalVars.VENDEMAIS_TABS_PAGE);
      });
    });
  }

  private setupEventCartUpdate(){
    let that = this;

    that.events.subscribe(EventKey.CART_UPDATE, (productCartMap) => {
      if (productCartMap != undefined && productCartMap != null) {
        let total: number = productCartMap.size;
        that.totalItemCart = (total > 0) ? total : null;
      } else {
        that.totalItemCart = null;
      }
    });
  }

  private setupEventCartRemoved(){
    let that = this;

    that.events.subscribe(EventKey.CART_REMOVED, () => {
      that.totalItemCart = null;
    });
  }

  private setupEventUserClientStorageChange(){
    let that = this;

    that.events.subscribe(EventKey.USER_CLIENT_STORAGE_CHANGED, (clientObj) => {
      that.showMenuItemBonusInfo = clientObj.usingLoyalty;
      that.clientObj = this.globalVars.getClientObj();
    });
  }

  private setupEventBeforeInstallPrompt(){
    let that = this;

    window.addEventListener('beforeinstallprompt', (e) => {

      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later on the button event.
      that.deferredPrompt = e;
      /*
        if (that.deferredPrompt && that.toast) {
          //TODO-M: that.toast.present();
        }
      */
    });
  }

  private setupEventSettingsChange() {

    let that = this;

    //Garante que se o settings ainda estiver vazio entao o mesmo deve ser setado.
    that.events.subscribe(EventKey.SETTINGS_CHANGED, (clientObj) => {
      this.settingsObj = this.globalVars.getSettingsObj();
    });
  }

  private buildCategoryMenuList(){
    this.getCategoryListQtProduct(() => {
      this.getCategoryList();
    });
  }

  private confirmReloadPage() {
    let that = this;

    let msgText = 'O app parece não ter carregado corretamente e isso pode implicar ' +
      'em mau funcionamento. Use a opção recarregar para tentar resolver o problema.';

    this.alertController.create({
      subHeader: 'Esta tendo problemas?',
      message: msgText,
      buttons: [
        {
          text: 'Recarregar',
          handler: () => {
            window.location.reload();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Cancelar clickado!!');
          }
        }
      ]
    }).then((alt)=>{
      alt.present();
    });
  }

  //TODO: Analisar outra forma de detectar se esta rodando em modo PWA.
  private setUpIsPwa() {
    let isPwa = document.URL.startsWith('http://localhost:8100') || (document.URL.startsWith('https'));
    this.globalVars.setIsPwa(isPwa);
  }

  setUpForPwaInstallButton() {

    window.addEventListener('appinstalled', (event) => {
      //alert('App Instalado!!');
    });

    // Detecta se o dispositivo está no iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    //Ver https://ryanve.com/lab/dimensions/
    if (!this.globalVars.isVisibleSplitPane() && window.innerHeight < window.screen.height) {
      this.showAddToHome();
    }
  }

  showAddToHome() {
    let that = this;
    //TODO-M
    /*
    that.toastCtrl.create({
      message: 'Instale sem ocupar espaço no seu celular.',
      duration: 7000,
      showCloseButton: true,
      closeButtonText: "Instalar",
      position: 'bootom'
    }).then((toast)=>{
      that.toast = toast;
    });

    that.toast.onDidDismiss(() => {
      that.deferredPrompt.prompt();
    });

    if (that.deferredPrompt) {
      that.toast.present();
    }
    */
  }

  getUserFirstName(): string {
    return Util.getUserFirstNameUser(this.userObj);
  }

  loadNotificationConfig(){

    if (this.platform.is('android') || this.platform.is('ios')) {

      let that = this;
      
      that.notificationConfigProvider.getNotificationConfig((notificationConfigData) => {
        if(notificationConfigData){
          
          let appId = notificationConfigData.appId;
          //TODO: Incuir essa configuracao na resposta do endpoint.
          let googleProjectNumber = (notificationConfigData.googleProjectNumber)?notificationConfigData.googleProjectNumber:"793176500711";
          
          //that.registePushOneSignal(appId, googleProjectNumber);
          that.oneSignalInit(appId);
        }
      },(errorData) => {
        if(errorData){
          console.error(errorData);
        }
      });
    }
  }

  /**Registra o device (quando app rodando na plataforma android ou ios) para receber notificacoes*/
  /*
  registePushOneSignal(appId:string, googleProjectNumber:string) {

      let that = this;

      if(appId && appId.trim().length > 0){
        that.oneSignal.startInit(appId, googleProjectNumber);

        //Aqui é caso vocë queria que o push apareça mesmo com o APP aberto
        that.oneSignal.inFocusDisplaying(that.oneSignal.OSInFocusDisplayOption.Notification);
        that.oneSignal.setSubscription(true);

        //Aqui você vai tratar o recebimento do push notification com todos os dados
        that.oneSignal.handleNotificationOpened().subscribe(data => {
          that.events.publish(EventKey.OPEN_NOTIFICATION, data);
        });

        that.oneSignal.endInit();

        that.oneSignal.getIds().then((data) => {
          if (data.userId) {
            that.storage.set(Util.ONE_SIGNAL_PLAYER_KEY, data.userId);
            that.saveOneSignalPlayerId(data.userId);
          }
        });

        that.startGetOneSignalPlayerId();
      }
  }*/

  oneSignalInit(appId:string): void {

    let that = this;
    
    OneSignal.setAppId(appId);
    
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
        that.events.publish(EventKey.OPEN_NOTIFICATION, jsonData);
    });
    
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    OneSignal.getDeviceState((response) => {
      console.log("OneSignal getDeviceState...");

      if (response.userId) {
        console.log("OneSignal UserId: "+response.userId);

        that.storage.set(Util.ONE_SIGNAL_PLAYER_KEY, response.userId);
        that.saveOneSignalPlayerId(response.userId);
      }
    });
  
    this.startGetOneSignalPlayerId();
  }

  //TODO: Analisar a real necessidade desse metodo
  //Caso não consiga pegar o PlayerId no momento no metodo registePushOneSignal
  startGetOneSignalPlayerId() {
    let that = this;

    that.storage.get(Util.ONE_SIGNAL_PLAYER_KEY).then((onesignalplayerid) => {
      if (!(this.platform.is('mobileweb') || this.platform.is('pwa') || this.platform.is('desktop'))) {
        if (!onesignalplayerid) {

          OneSignal.getDeviceState((response) => {
            console.log("OneSignal getDeviceState...");
      
            if (response.userId) {
              console.log("OneSignal UserId: "+response.userId);
      
              that.storage.set(Util.ONE_SIGNAL_PLAYER_KEY, response.userId);
              that.saveOneSignalPlayerId(response.userId);
            }
          });

          that.timeout = setTimeout(function () {
            that.startGetOneSignalPlayerId();
          }, 8000);

        } else {
          if (that.timeout) {
            clearTimeout(that.timeout);
          }
          that.saveOneSignalPlayerId(onesignalplayerid);
        }
      }
    });

  }

  saveOneSignalPlayerId(onesignalplayerid) {
    this.oneSiginalPlayerProvider.saveOneSignalPlayerIdForBuyer(onesignalplayerid, (type, data) => {
      if (type == 'sucess') {
        console.log("OneSigalPlayerId salvo com sucesso!!");
      } else {
        console.error(data);
      }
    });
  }

  getUserInLocalStorage() {
    let that = this;

    this.userService.getStoragedLocalUser((userObj) => {
      if (userObj) {
        let buyerObj = JSON.parse(userObj);

        that.globalVars.setBuyerObj(buyerObj);
        that.setUserObj(buyerObj);
        that.loadNotifyMeListInLocalStorage(buyerObj);
      } else {
        that.setUserObj(null);
        that.storage.set(Util.ONE_SIGNAL_PLAYER_KEY, null);
      }
    });
  }

  setUserObj(userObj){
    this.userObj = userObj;
  }

  loadUserClient() {
    let that = this;

    that.clientProvider.getUserClient((userClientData) => {
      that.storage.set(Util.USER_CLIENT_STORAGE_KEY, JSON.stringify(userClientData));
      that.events.publish(EventKey.USER_CLIENT_STORAGE_CHANGED, userClientData);
    },(errorData) => {
      console.error("Error: " + JSON.stringify(errorData));
    });
  }

  loadClient(){
    let that = this;

    that.clientService.getClient((clientData)=>{

      if (clientData) {
        that.clientObj = clientData;

        that.globalVars.setClientObj(clientData);
        that.showMenuItemBonusInfo = clientData.usingLoyalty;
        
        that.clientService.getChildren(
          (sucessData)=>{
            that.globalVars.setClientChildren(sucessData);
            that.validateAppVersino(clientData);
          },
          (errorData)=>{
            console.error(errorData);
          }
        );
      }

      that.getSettings();
      that.enableSharingMenu();

    }, (errorData) => {
      console.error(errorData);
    });
  }

  private validateAppVersino(clientData: any) {
    let that = this;
    let appVersion = clientData.appVersion;

    this.validateVersion(appVersion, (isValid) => {
      if (isValid) {
        if(this.isChildrenClient()){
            that.openClientListPage(appVersion);
        }
      } else {
        that.openUpdateVersionPage();
      }
    });
  }

  openClientListPage(appVersion) {
   /*
    * Se versao no VendeMais ZERO então não mostra a Client List Page. 
    * Regra criada apenas para ajudar nos testes com ambiente Moda.
    */
    if(appVersion != 0){
      this.isShowClientList = true;
      this.setRooPage(GlobalVars.CLIENT_LIST_PAGE);
    }
  }

  private isChildrenClient(){
   return (this.clientObj && 
    this.globalVars.getClientChildren() && 
    this.globalVars.getClientChildren().length > 0);
  }

  private getSettings() {
    let that = this;
    
    //Garante o carregamento do Settings anteriormente carregado caso a busca do Settings do servidor demore.
    this.storage.get(Util.SETTINGS_KEY).then((storagedSettings) => {
      if (storagedSettings) {
        that.globalVars.setSettingsObj(storagedSettings);
        that.settingsObj = storagedSettings;
        that.showMenuItemWishlist = that.settingsObj.usingWishlist;
      }
    });

    this.settingsProvider.getSettings((sucessData) => {
      that.globalVars.setSettingsObj(sucessData);
      that.settingsObj = sucessData;
      that.showMenuItemWishlist = that.settingsObj.usingWishlist;
      that.storage.set(Util.SETTINGS_KEY, sucessData);
    }, (error) => {
      console.error(error);
    });
  }

  loadNotifyMeListInLocalStorage(userObj) {

    if (this.userObj) {
      this.notifyMeProvider.getPedingNotifyMeList(userObj.id, (notifyMeList) => {

        if (notifyMeList) {

          this.notifyMeProvider.storageNotifyMeList(notifyMeList);
        }
      }, (errorData) => {
        console.error(errorData);
      });
    }
  }

  validateVersion(serverAppVersion: number, callBack) {
    if (!serverAppVersion || serverAppVersion <= this.globalVars.getAppVersionNumber()) {
      if (callBack) {
        callBack(true);
      }
    } else {
      callBack(false);
    }
  }

  openUpdateVersionPage() {
    //TODO: Recever essa validação para o uso do reload no forçar atualização do PWA.
    if (!this.globalVars.isPwa()) {
      this.setRooPage(GlobalVars.UPDATE_VERSION_ROOT_PAGE);
    }
  }

  setRooPage(rootPage: any) {
    
    this.rootPage = rootPage;
    this.globalVars.setActiveRootPage(this.rootPage);

    if (rootPage == GlobalVars.VENDEMAIS_TABS_PAGE) {
      //TODO-M: this.rootPage = VendemaisTabsPage;
    }else if (rootPage == GlobalVars.TABS_ROOT_PAGE) {
      this.router.navigateByUrl("/tabs");
    }else if (rootPage == GlobalVars.UPDATE_VERSION_ROOT_PAGE) {
      this.router.navigateByUrl("/update-version-info");
    }else if (rootPage == GlobalVars.HOME_ROOT_PAGE) {
      this.router.navigateByUrl("/home");
    }else if (rootPage == GlobalVars.CLIENT_LIST_PAGE) {
      this.router.navigateByUrl("/client-list");
    } 
  }

  /**TODO: Esses metodos de cateoria para obter lista e quanidde de produtos devem ser refatorados para um unico metodo.
   * a solução atual foi para contornar o fato da informacao de Quantidade de Produtos por categoria 
   * não vir no objeto Category padrao do sistema carregado em loadAllCategory().
   */
  getCategoryListQtProduct(callBack: any) {
    let that = this;

    that.categoryService.getBasicCategory((categoryList: any) => {

      for (let category of categoryList) {
        that.categoryListProductQt.set(category.id, category.totalProducts);
      }

      callBack()
    }, (error: any) => {
      console.error(error);
    });

  }

  getCategoryList() {
    let that = this;

    that.categoryService.getBasicCategory((data: any) => {

      for (let categoryParent of data) {
        let total = 0;

        for (let sub of categoryParent.children) {
          let qtSub: number = that.categoryListProductQt.get(sub.id);
          total = (qtSub) ? total + qtSub : total;
        }
        if (total > 0) {
          that.categoryListProductQt.set(categoryParent.id, total);
        }
      }

      that.categoryList = Util.getOrderedCategoryList(data);

    }, (error: any) => {
      console.error(error);
    });
  }

  openHomePage() {
    this.events.publish(EventKey.OPEN_HOME);
  }

  openCategoryPage() {
    if (this.globalVars.isHomePageRootActive()) {
      //TODO-M: this.nav.push("CategoryPage");
    }
    this.events.publish(EventKey.MENU_CATEGORY);
  }

  openCartPage() {
    if (this.globalVars.isVisibleSplitPane()) {
      this.router.navigate(['/cart']);
    }

    this.events.publish(EventKey.OPEN_CART);
    this.globalVars.setPageLoaded('CartPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "CartPage");
  }

  openFidelityPage() {
    if (this.globalVars.isHomePageRootActive()) {
      //TODO-M: this.nav.push("FidelityViewPage");
    }
    this.events.publish(EventKey.OPEN_BONUS);
  }

  openOrderListPage() {
    if (this.globalVars.isHomePageRootActive()) {
      //TODO-M: this.nav.push("OrderListPage");
    }
    this.events.publish(EventKey.MENU_ORDER_LIST);
  }

  openProductListBy(category: any) {

    this.navigationDataService.setData("ProductListPage",{
      categoryParent: category
    });

    this.router.navigate(['/product-list']);
    this.globalVars.setPageLoaded('ProductListPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "ProductListPage");
  }

  closeApp() {
    //TODO-M: this.platform.exitApp();
  }

  async openEntry() {

    let opts:ModalOptions = {
      component: LoginPage,
      mode: 'ios',
    };

    if(this.globalVars.isVisibleSplitPane()){
      opts.cssClass = 'login-modal';
    }
    
    this.navigationDataService.setData("LoginPage",{
      origin: Origin.MENU,
      modal: true
    });
    
    const modal = await this.modalController.create(opts);
    modal.present();

    this.globalVars.setPageLoaded('EntryPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "EntryPage");
  }

  async openBonusInfo() {

    const modal = await this.modalController.create(
      {
        component: WalletInfoPage,
        mode: 'ios',
        cssClass: 'create-wishlist-modal'
      }
    );

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/wallet-info']);
    }

    this.events.publish(EventKey.OPEN_BONUS_INFO);
    this.globalVars.setPageLoaded('BonusInfoPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "BonusInfoPage");
  }

  async openWishlist(){

    const modal = await this.modalController.create(
      {
        component: WishlistPage,
        mode: 'ios',
        cssClass: 'create-wishlist-modal'
      }
    );

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/wishlist']);
    }
  }

  async openContactUs() {
    
    const modal = await this.modalController.create(
      {
        component: ContactUsPage,
        mode: 'ios',
        cssClass: 'create-wishlist-modal'
      }
    );

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/contact-us']);
    }

    this.globalVars.setPageLoaded('ContatcusPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "ContatcusPage");
  }

  async openAbount() {

    let params: any = { origin: Origin.HOME, callBack: (buyerObj) => { } };

    const modal = await this.modalController.create(
      {
        component: AboutAppPage,
        mode: 'ios',
        cssClass: 'about-modal'
      }
    );
    
    this.navigationDataService.setData("AboutAppPage", params);

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/about-app']);
    }

    this.events.publish(EventKey.OPEN_ABOUT);
    this.globalVars.setPageLoaded('AboutAppPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "AboutAppPage");
  }

  async openPaymentDeliveryInfo() {

    const modal = await this.modalController.create(
      {
        component: DeliveryPaymentInfoPage,
        mode: 'ios',
        cssClass: 'create-wishlist-modal'
      }
    );

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/delivery-payment-info']);
    }

    this.events.publish(EventKey.OPEN_PAYMENT_DELIVERY_INFO);
    this.globalVars.setPageLoaded('DeliveryPaymentInfoPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "DeliveryPaymentInfoPage");
  }

  sharing() {
    //this.socialSharing.share(this.socialMediaDescription, "", this.socialMediaImage, this.socialLink);
  }

  enableSharingMenu() {

    if (this.clientObj && this.clientObj) {
      let owner = this.clientObj;

      this.socialMediaDescription = owner.socialMediaText;
      this.socialMediaImage = Util.getImagePath(this.globalVars.getBaseURL(), owner.socialMediaImage);
      this.socialLink = null;

      if (owner.socialMediaLinkAndroid || owner.socialMediaLinkApple) {
        if (owner.socialMediaLinkAndroid == owner.socialMediaLinkApple) {
          this.socialLink = owner.socialMediaLinkAndroid;
        } else if (this.platform.is('android')) {
          this.socialLink = owner.socialMediaLinkAndroid;
        } else if (this.platform.is('ios')) {
          this.socialLink = owner.socialMediaLinkApple;
        }
      }

      if (this.socialMediaDescription && this.socialMediaDescription.trim().length > 0 &&
        this.socialMediaImage && this.socialMediaImage.trim().length > 0 &&
        this.socialLink && this.socialLink.trim().length > 0) {

        this.showShareMenu = true;
      } else {
        this.showShareMenu = false;
      }

    } else {
      this.showShareMenu = false;
    }
  }

  async logIn() {

    let opts:ModalOptions = {
      component: LoginPage,
      mode: 'ios',
    };

    if(this.globalVars.isVisibleSplitPane()){
      opts.cssClass = 'login-modal';
    }
    
    this.navigationDataService.setData("LoginPage",{
      origin: Origin.MENU,
      modal: true
    });
    
    const modal = await this.modalController.create(opts);
    modal.present();

    this.globalVars.setPageLoaded('EntryPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "EntryPage");
  }

  logInAdmin() {
    let dataSend = { origin: Origin.MENU_LOGIN_ADMIN_USER };
    //TODO-M: this.nav.push('EntryPage', dataSend);

    this.globalVars.setPageLoaded('EntryPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "EntryPage");
  }

  async logOut() {
    let that = this;
    
    const modal = await this.alertController.create({
      subHeader: 'Sair',
      message: 'Deseja realmente sair ?',
      buttons: [
        {
          text: 'Não',
          handler: () => { }
        },
        {
          text: 'Sim',
          handler: () => {
            if (this.globalVars.isLoggedInVendeMais()) {
              that.logOutVendeMais();
            } else {
              that.logOutBuyer();
            }
          }
        }
      ]
    });

    modal.present();
  }

  logOutBuyer() {
    this.userService.storageLocalUser(null);
    this.globalVars.setBuyerObj(null);
    this.setUserObj(null);
    this.events.publish(EventKey.LOGOUT);
  }

  logOutVendeMais() {
    let that = this;
    let loading;

    this.loadingControle.create({
      message: 'Saindo...'
    }).then((ldn)=>{
      loading = ldn;
      ldn.present();
    });

    this.storage.set(Util.VENDEMAIS_LOGED_KEY, false).then(() => {

      that.events.publish(EventKey.LOGOUT_VENDEMAIS);

      setTimeout(function () {
        that.globalVars.setLoggedInVendeMais(false);
        loading.dismiss();
        that.setUpRootPage();
      }, 3000);

    });
  }


  openUserData() {
    
    let params:any = {
        origin: Origin.MENU_EDIT_USER, 
        userObj: this.userObj
    };

    this.openCreateUserPage(params);

    this.globalVars.setPageLoaded('CreateUserPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "CreateUserPage");
  }

  private async openCreateUserPage(params: any) {

    if (this.globalVars.isVisibleSplitPane()) {

      params.modal = true;
      this.navigationDataService.setData("CreateUserPage", params);

      const modal = await this.modalController.create(
        {
          component: CreateUserPage,
          mode: 'ios',
          cssClass: 'create-user-modal'
        }
      );

      modal.present();
    } else {

      this.navigationDataService.setData("CreateUserPage", params);
      this.router.navigate(['/create-user']);
    }
  }

  isSplitPaneVisible(event) {
    /*
    if (event._visible) {
      this.globalVars.setIsVisibleSplitPane(true);
      this.events.publish(EventKey.VISIBLE_SPLIT_PANEL);
    } else {
      this.globalVars.setIsVisibleSplitPane(false);
      this.events.publish(EventKey.HIDDEN_SPLIT_PANEL);
    }*/
  }

  setUpNumeral() {
    // load a locale
    numeral.register('locale', 'pt-br', {
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      abbreviations: {
        thousand: 'mil',
        million: 'milhões',
        billion: 'b',
        trillion: 't'
      },
      ordinal: function (number) {
        return 'º';
      },
      currency: {
        symbol: 'R$'
      }
    });

    numeral.locale('pt-br');
  }

  showAboutLink(): boolean {
    return (this.settingsObj
      && this.settingsObj.about
      && this.settingsObj.about.trim().length > 15);
  }

  showTermsAndConditionsLink(): boolean {
    return (this.settingsObj
      && this.settingsObj.termsAndConditions
      && this.settingsObj.termsAndConditions.trim().length > 15);
  }

  showPrivacyAndPoliciesLink(): boolean {
    return (this.settingsObj
      && this.settingsObj.privacyAndPolicies
      && this.settingsObj.privacyAndPolicies.trim().length > 15);
  }

  isShowSideBar(): boolean {

    if (this.isShowClientList) {
      return false;
    } else if (this.isLarge() && this.settingsObj) {
      return !this.settingsObj.desktopLayout || this.settingsObj.desktopLayout == "default";
    } else if (this.settingsObj) {
      return true;
    }
  }

  isShowOnlyNav(): boolean {
    if (this.isShowClientList) {
      return true;
    } else if (this.isLarge()) {
      return this.settingsObj && (this.settingsObj.desktopLayout && this.settingsObj.desktopLayout != "default");
    } else {
      return false;
    }
  }

  showHeaderSideBar(): boolean {
    if (this.isLarge()) {
      if(this.settingsObj)
        return (!this.settingsObj.desktopLayout || this.settingsObj.desktopLayout == "default");
      else 
        return true;
    }else{
      return false;
    }
  }

  isLarge(): Boolean {
    return window.innerWidth > 992;
  }

  async openDialog(contentType: string) {

    let params: any = { contentType: contentType, callBack: (buyerObj) => { } };

    const modal = await this.modalController.create(
      {
        component: HtmlContentPage,
        mode: 'ios',
        cssClass: 'about-modal'
      }
    );
    
    this.navigationDataService.setData("HtmlContentPage",params);

    if (this.globalVars.isVisibleSplitPane()) {
      modal.present();
    } else {
      this.router.navigate(['/html-content']);
    }

    this.globalVars.setPageLoaded('HtmlContentPage', false);
    this.events.publish(EventKey.CLICK_OPEN_PAGE, "HtmlContentPage");
  }

  loadListMethodPayment() {
    let that = this;

    that.methodPaymentProvider.getListMethosPayment((listPayment) => {
      
      if(listPayment && listPayment.length > 0){
        
        that.globalVars.setListWayPayment(listPayment);

        for(let wayPayment of listPayment){
          if(wayPayment.type == 'CARD'){
            that.globalVars.setCardWayPayment(wayPayment);
          }
        }
      }

    }, (objError) => {
      console.error(objError);
    });
  }
}
